<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">발주현황</h2>
      </div>
      <div class="body_section">
        <OrderListFilterBar
          ref="filterBar"
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :statusList="statusList"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 발주 상태 -->
            <col style="width: 108px" />
            <!-- 발주일 -->
            <col style="width: 94px" />
            <!-- 발주 번호 -->
            <col style="width: 156px" />
            <!-- 발주서명 -->
            <col />
            <!-- 품목 -->
            <col style="width: 152px" />
            <!-- 발주 업체 -->
            <col style="width: 114px" />
            <!-- 구매 담당자 -->
            <col style="width: 173px" />
            <!-- 발주 확인일 -->
            <col style="width: 120px" />
            <!-- 발주 확인 -->
            <col style="width: 129px" />
          </template>
          <template v-slot:tr>
            <th>발주 상태</th>
            <th>발주일</th>
            <th>발주 번호</th>
            <th>발주서명</th>
            <th>품목</th>
            <th>발주 업체</th>
            <th>구매 담당자</th>
            <th>발주 확인일</th>
            <th>발주 확인</th>
          </template>
          <template v-slot:row="slotProps">
            <OrderListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="9" class="td_empty">발주가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import OrderListFilterBar from "@/components/user/order/list/OrderListFilterBar";
import OrderListLine from "@/components/user/order/list/OrderListLine";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import { dateToString, stringToDate } from "@/utils/dateUtils";

import { mapState } from "vuex";
import { ORDER_LIST_ACTION } from "@/store/modules/order/action";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

export default {
  components: {
    PageWithLayout,
    OrderListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    OrderListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.ORDER,

      KEYWORD_DATAS: [
        { id: "orderCid", code: "orderCid", desc: "발주번호" },
        { id: "title", code: "title", desc: "발주서명" },
        { id: "name", code: "name", desc: "구매담당자" },
      ],

      defaultKeywordSelectedId: "orderCid",
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(ORDER_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.order.statusList,
    }),
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      const objDates = this.checkQueryDates(query, "orderDate1", "orderDate2");

      const objStatus = this.checkQueryStatus(query);

      const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus, objCateNum);
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        status,
        category0,
        category1,
        category2,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["orderDate1"] = startDt;
        params["orderDate2"] = endDt;
      }

      if (status) {
        params.status = status;
      }

      const cateNum = category2 || category1 || category0;

      if (cateNum) {
        params.cateNum = cateNum;
      }

      return params;
    },
  },
};
</script>
