<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl class="w152">
      <dt>발주 상태</dt>
      <dd>
        <SelectBox
          class="w152"
          :dataList="statusList"
          :isDisabled="isDisabledStatus"
          :value.sync="stateId"
        />
      </dd>
    </dl>
    <dl>
      <dt>발주일</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>

      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            name="filterSearch"
            class="w208"
            :dataList="keywordDatas"
            :value.sync="keywordSelectedId"
          />
          <Input class="w425" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
  },
  mixins: [FilterBarMixin],
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.onClickResetFilterBar();
      this.stateId = "0";
    },
  },
};
</script>
