<template>
  <ListRow>
    <td>
      <span :class="['badge_state', stateType]">{{ rowData.statusName || "" }}</span>
    </td>
    <td>{{ orderDate }}</td>
    <td>{{ rowData.orderCid }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.title">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      />
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.goodsItem">
      {{ rowData.itemName }}
    </td>
    <td>{{ rowData.companyName }}</td>
    <td>{{ rowData.name }}</td>
    <td>{{ rowData.agreeDate | dateTimeStringFormat | nullToDash }}</td>
    <td>{{ rowData.partnerPersonName }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
// import { getDateString } from '@/utils/dateUtils';
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "OrderListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  computed: {
    toPath() {
      const { orderCid } = this.rowData;

      const path = makePathWithQuery(this.$routerPath.ORDER_VIEW, orderCid, this.$route.query);
      return path;
    },
    stateType() {
      switch (this.rowData.status) {
        case "Y":
          return "on";
        case "N":
          return "disabled";
      }
      return "";
    },
  },
};
</script>
